// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()


import { Graph } from "graphology"
import { Sigma } from "sigma"
import NoverlapLayout from 'graphology-layout-noverlap/worker';
import FA2Layout from "graphology-layout-forceatlas2/worker";
import forceAtlas2 from "graphology-layout-forceatlas2";
import louvain from 'graphology-communities-louvain';
import clusters from "graphology-generators/random/clusters";
import data from "../data.json";
import data2 from "../data2.json";
import { saveAsPNG } from './saveGraph'


document.addEventListener('DOMContentLoaded', function () {
    if (typeof $ != 'undefined' && $('#headergraph').length) {
        const container = document.getElementById("headergraph");
        const graph = new Graph();
        graph.import(data2);

        // node sizes
        const sizes = graph.nodes().map(function (node) { return graph.getNodeAttribute(node, 'size') });
        const minNodeSize = Math.min.apply(Math, sizes);
        const maxNodeSize = Math.max.apply(Math, sizes);
        const minSize = 2, maxSize = 14;
        graph.updateEachNodeAttributes((node, attr) => {
            return {
                ...attr,
                size: minSize + ((attr.size - minNodeSize) / (maxNodeSize - minNodeSize)) * (maxSize - minSize)
            };
        });

        let renderer = new Sigma(graph, container, {
            minCameraRatio: 0.1,
            maxCameraRatio: 20
        });

        renderer.getMouseCaptor().kill();
        renderer.getTouchCaptor().kill();
        

        renderer.getMouseCaptor().on('doubleClick', (e) => {
            e.preventSigmaDefault();
        });
        renderer.setSetting("renderLabels",false)
        const camera = renderer.getCamera();
        camera.setState({ ratio: 0.125 , x: 0.65, y: 0.55});

        const layout = new FA2Layout(graph, {
            settings: {
                linLogMode: false,
                outboundAttractionDistribution: true,
                adjustSizes: true,
                edgeWeightInfluence: 0,
                scalingRatio: 1,
                strongGravityMode: false,
                gravity: 0.05,
                slowDown: 500,
                barnesHutOptimize: true,
                barnesHutTheta: 0.6
            }
        });

        layout.start();

        setTimeout(() => {
            layout.stop();
        }, 180000);

        renderer.refresh();
    }
    


    if (!!document.getElementById("graph-container")) {
        if (typeof color != 'undefined' && color == "white") {
            var body = document.getElementsByTagName('body')[0];
            body.style.background = '#FFF';
        }
        const button4 = document.getElementById("animation_4")
        const container = document.getElementById("graph-container")

        const graph = new Graph();

        graph.import(data)

    // node sizes
        const sizes = graph.nodes().map(function (node) { return graph.getNodeAttribute(node, 'size') })
        const minNodeSize = Math.min.apply(Math, sizes)
        const maxNodeSize = Math.max.apply(Math, sizes)
        const minSize = 2, maxSize = 20;
        graph.updateEachNodeAttributes((node, attr) => {
            //attr.size
            return {
                ...attr,
                size: minSize + ((attr.size - minNodeSize) / (maxNodeSize - minNodeSize)) * (maxSize - minSize)
            }
        })

        const layout = new FA2Layout(graph, {settings: {gravity: 1, barnesHutOptimize: true, adjustSizes: true, scalingRatio: 100, slowDown: 20}, weighted: true});

        let renderer = new Sigma(graph, container, {
            minCameraRatio: 0.1,
            maxCameraRatio: 10,

        });

        renderer.getMouseCaptor().on('doubleClick', (e) => {
            e.preventSigmaDefault();
        })

        "use strict";
        /**
         * This example showcases sigma's reducers, which aim to facilitate dynamically
         * changing the appearance of nodes and edges, without actually changing the
         * main graphology data.
         */
        var __assign = (this && this.__assign) || function () {
            __assign = Object.assign || function(t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i];
                    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                        t[p] = s[p];
                }
                return t;
            };
            return __assign.apply(this, arguments);
        };
    //Object.defineProperty(exports, "__esModule", { value: true });

        var state = { searchQuery: "" };
        function setHoveredNode(node) {
            if (node) {
                state.hoveredNode = node;
                state.hoveredNeighbors = new Set(graph.neighbors(node));
            }
            else {
                state.hoveredNode = undefined;
                state.hoveredNeighbors = undefined;
            }
            // Refresh rendering:
            renderer.refresh();
        }
    // Bind graph interactions:
        renderer.on("enterNode", function (_a) {
            var node = _a.node;
            setHoveredNode(node);
            if (color === "white") {
                renderer.setSetting("labelColor", {color:"#333"})
            }
            else {
                renderer.setSetting("labelColor", {color:"#778899"})
            }
        });
        renderer.on("leaveNode", function () {
            setHoveredNode(undefined);
            if (color === "white") {
                renderer.setSetting("labelColor", {color:"#333"})
            }
            else {
                renderer.setSetting("labelColor", {color:"#fff"})
            }
        });
    // Render nodes accordingly to the internal state:
    // 1. If a node is selected, it is highlighted
    // 2. If there is query, all non-matching nodes are greyed
    // 3. If there is a hovered node, all non-neighbor nodes are greyed
        renderer.setSetting("nodeReducer", function (node, data) {
            const res = __assign({}, data)
            if (state.hoveredNeighbors && !state.hoveredNeighbors.has(node) && state.hoveredNode !== node) {
                res.hidden = true
            }
            if (state.selectedNode === node) {
                res.highlighted = true
            }
            else if (state.suggestions && !state.suggestions.has(node)) {
                res.hidden = true
            }
            return res;
        });
    // Render edges accordingly to the internal state:
    // 1. If a node is hovered, the edge is hidden if it is not connected to the
    //    node
    // 2. If there is a query, the edge is only visible if it connects two
    //    suggestions
        renderer.setSetting("edgeReducer", function (edge, data) {
            var res = __assign({}, data);
            if (state.hoveredNode && !graph.hasExtremity(edge, state.hoveredNode)) {
                res.hidden = true;
            }
            if (state.suggestions && (!state.suggestions.has(graph.source(edge)) || !state.suggestions.has(graph.target(edge)))) {
                res.hidden = true;
            }
            return res;
        });

        renderer.setSetting("labelRenderedSizeThreshold", 0);
        layout.start()
        button4.addEventListener('click', e => {
            animation_4()})

        function animation_4(){
            if (layout.isRunning() === true) {
                layout.stop()
                button4.innerHTML = "Start"
            }
            else {
                layout.start()
                button4.innerHTML = "Stopp"
            }
        }

        if (color === "white") {
            graph.forEachNode(function (node) {
                return graph.setNodeAttribute(node, "color", '#337ab7');
            });
            renderer.setSetting("labelColor", {color:"#333"})
            renderer.setSetting("defaultEdgeColor", '#a1c703')
            renderer.setSetting("labelDensity", 10)
            renderer.setSetting("labelGridCellSize", 150)
        }
        else {
            graph.forEachNode(function (node) {
                return graph.setNodeAttribute(node, "color", '#337ab7');
            });
            renderer.setSetting("labelColor", {color:"#fff"})
            renderer.setSetting("defaultEdgeColor", '#a1c703')
            renderer.setSetting("defaultNodeColor", "#fff")
            renderer.setSetting("labelDensity", 10)
            renderer.setSetting("labelGridCellSize", 150)
        }
    }




    // Klappmenü-zeug
    $('#menuBtnUp').click(function() {
        $('#menuBtnUp').hide()
        $('#menuBtnDown').show()
        $('#GraphMenu').hide()
    })
    $('#menuBtnDown').click(function() {
        $('#menuBtnUp').show()
        $('#menuBtnDown').hide()
        $('#GraphMenu').show()
    })

    if (/Mobi/.test(navigator.userAgent)) {
        $('#menuBtnUp').hide()
        $('#menuBtnDown').show()
        $('#GraphMenu').hide()
    }



    $('#netz_der_woche').click(function(){
        _paq.push(['trackEvent', 'CTA - Button', 'CTA-oben']);
    });
    $('#netz_der_woche2').click(function(){
        _paq.push(['trackEvent', 'CTA - Button', 'CTA-unten']);
    });



    if ($('#ndw-container').length) {
        if (graphJSON.length < 1 || graphJSON.edges.length < 1) {
            $('#ndw-container').text('Es gibt keine Daten für diesen Graph.');
            return;
        }

        const container = document.getElementById("ndw-container")

        const graph = new Graph();

        graph.import(graphJSON)

        // node sizes
        const sizes = graph.nodes().map(function (node) { return graph.getNodeAttribute(node, 'size') })
        const minNodeSize = Math.min.apply(Math, sizes)
        const maxNodeSize = Math.max.apply(Math, sizes)
        const minSize = 2, maxSize = 14;
        graph.updateEachNodeAttributes((node, attr) => {
            //attr.size
            return {
                ...attr,
                size: minSize + ((attr.size - minNodeSize) / (maxNodeSize - minNodeSize)) * (maxSize - minSize)
            }
        })

        const layout = new FA2Layout(graph, {settings: {gravity: 1, barnesHutOptimize: true, adjustSizes: true, scalingRatio: 100}, weighted: true});

        var zoomInBtn = document.getElementById("zoom-in");
        var zoomOutBtn = document.getElementById("zoom-out");
        var zoomResetBtn = document.getElementById("zoom-reset");
        var renderer = new Sigma(graph, container, {
            minCameraRatio: 0.1,
            maxCameraRatio: 10,
            allowInvalidContainer: true
        });
        var camera = renderer.getCamera();
        // Bind zoom manipulation buttons
        zoomInBtn.addEventListener("click", function () {
            camera.animatedZoom({ duration: 600 });
        });
        zoomOutBtn.addEventListener("click", function () {
            camera.animatedUnzoom({ duration: 600 });
        });
        zoomResetBtn.addEventListener("click", function () {
            camera.animatedReset({ duration: 600 });
        });

        camera.setState({ratio: cameraRatio});

        "use strict";
        /**
         * This example showcases sigma's reducers, which aim to facilitate dynamically
         * changing the appearance of nodes and edges, without actually changing the
         * main graphology data.
         */
        var __assign = (this && this.__assign) || function () {
            __assign = Object.assign || function(t) {
                for (var s, i = 1, n = arguments.length; i < n; i++) {
                    s = arguments[i];
                    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                        t[p] = s[p];
                }
                return t;
            };
            return __assign.apply(this, arguments);
        };
        //Object.defineProperty(exports, "__esModule", { value: true });

        var state = { searchQuery: "" };
        function setHoveredNode(node) {
            if (node) {
                state.hoveredNode = node;
                state.hoveredNeighbors = new Set(graph.neighbors(node));
            }
            else {
                state.hoveredNode = undefined;
                state.hoveredNeighbors = undefined;
            }
            // Refresh rendering:
            renderer.refresh();
        }
        // Bind graph interactions:
        renderer.on("enterNode", function (_a) {
            var node = _a.node;
            setHoveredNode(node);
        });
        renderer.on("leaveNode", function () {
            setHoveredNode(undefined);
        });
        // Render nodes accordingly to the internal state:
        // 1. If a node is selected, it is highlighted
        // 2. If there is query, all non-matching nodes are greyed
        // 3. If there is a hovered node, all non-neighbor nodes are greyed
        renderer.setSetting("nodeReducer", function (node, data) {
            const res = __assign({}, data)
            if (state.hoveredNeighbors && !state.hoveredNeighbors.has(node) && state.hoveredNode !== node) {
                res.hidden = true
            }
            if (state.selectedNode === node) {
                res.highlighted = true
            }
            else if (state.suggestions && !state.suggestions.has(node)) {
                res.hidden = true
            }
            return res;
        });

        renderer.setSetting("labelDensity", .5);
        renderer.setSetting("labelGridCellSize", 10);
        renderer.setSetting("labelRenderedSizeThreshold", labelRenderedSizeThreshold);
        // Render edges accordingly to the internal state:
        // 1. If a node is hovered, the edge is hidden if it is not connected to the
        //    node
        // 2. If there is a query, the edge is only visible if it connects two
        //    suggestions
        renderer.setSetting("edgeReducer", function (edge, data) {
            var res = __assign({}, data);
            if (state.hoveredNode && !graph.hasExtremity(edge, state.hoveredNode)) {
                res.hidden = true;
            }
            if (state.suggestions && (!state.suggestions.has(graph.source(edge)) || !state.suggestions.has(graph.target(edge)))) {
                res.hidden = true;
            }
            return res;
        });

        $('#animationSwitch').change(function () {
            if($(this).is(":checked")){
                layout.start()
            }
            else {
                layout.stop()
            }
        });


        $('#backgroundSwitch').change(function () {
            if($(this).is(":checked")){
                $('footer').css('background-color','#1f1f1f');
                $('.block').css('background','rgb(30, 30, 30)');
                $('#hiddenContent').css('background','rgb(48, 48, 48)');
                $('.keywords').css('color','#fff');
                $('.sigma-edges').css('background-color','#383838')
                $('.first-block').css('background-color','rgb(30, 30, 30)');
                $('.second-block').css('background-color','rgb(30, 30, 30)');
                $('.previousNets').css('background-color','rgb(48, 48, 48)');
                $('.box-with-border').css('color','#fff');
                $('.cta-text').css('color','#fff');
                $('.headline').css('color','#fff');
                $('.headline-3').css('color','#fff');
                $('.P').css('color','#fff');
                $('blockquote-website').css('background-color','#000');
                $('.text-big').css('color','#fff');
                $('.white-text').css('color','#fff');
                $('.modal__container').css('background-color','#242424')
                $('.modal__content').css('color','#fff','important')
                $('.modal__footer').css('background-color','#242424')
                $("body").css({"background-color":'rgb(30, 30, 30)'});
                renderer.setSetting("labelColor", {color: "#fff"});
            }
            else {
                $('.sigma-edges').css('background-color','white')
                $('.block').css('background','#f6f6f6');
                $('#hiddenContent').css('background','rgb(232, 232, 232)');
                $('.keywords').css('color','#000');
                $('.first-block').css('background-color','rgba(255, 255, 255, 0.7)');
                $('.second-block').css('background-color','rgba(255, 255, 255, 0.7)');
                $('.previousNets').css('background-color','rgb(232, 232, 232)');
                $('.box-with-border').css('color','#000');
                $('.cta-text').css('color','#000');
                $('.headline').css('color','#000');
                $('.headline-3').css('color','#000');
                $('.P').css('color','#000');
                $('blockquote-website').css('background-color','#eee');
                $('.text-big').css('color','#000');
                $('.white-text').css('color','#000');
                $('.modal__container').css('background-color','#fff')
                $('.modal__content').css('color','#000','important')
                $('.modal__footer').css('background-color','#fff')
                $("body").css({"background-color":'#fdfdfd'});
                renderer.setSetting("labelColor", {color: "#000"});
            }
        });
        var defaultEdgeColor = graphJSON.edges[0].attributes.color
        var defaultNodeColor = graphJSON.nodes[0].attributes.color

        // edge and node colour change
        const communities = louvain(graph)
        const nodeColours = [
            nodeColor1,
            nodeColor2,
            nodeColor3,
            nodeColor4,
            nodeColor5
        ]
        const edgeColours = [
            edgeColor1,
            edgeColor2,
            edgeColor3,
            edgeColor4,
            edgeColor5
        ]

        graph.updateEachEdgeAttributes((edge, attr) => {
            return {
                ...attr,
                color: edgeColours[communities[graph.source(edge)]] || defaultEdgeColor
            }
        })
        graph.updateEachNodeAttributes((node, attr) => {
            return {
                ...attr,
                color: nodeColours[communities[node]] || defaultNodeColor
            }
        })


        var saveBtn = document.getElementById("btn-png");
        saveBtn.addEventListener("click", function () {
            var layers = ["edges", "nodes", "labels"]
            saveAsPNG(renderer, layers, Sigma)
        });


    }
    $('form[id^=netz_der_woche]').submit(function(e) {
        if($(this).closest('form')[0].checkValidity()){
            e.preventDefault();
            var data = $(this).serialize();
            $.ajax({
                url: '/contacts',
                type: 'post',
                data: data
            }).done(function() {
                MicroModal.show('success');
                $('.email-field').prop('disabled', true);
                $('.submit-button').prop('disabled', true);

            }).fail(function(jqXHR, textStatus, errorThrown) {
                if (jqXHR.responseJSON.errors[0] == 'Bitte mindestens ein Feld ausfüllen') {
                    var errorHtml = '';
                    $(jqXHR.responseJSON.errors).each(function(i, error) {
                        errorHtml += '<li>' + error + '</li>';
                    });
                    $('#fail-content').html('<ul>' + errorHtml + '</ul>');
                }
                MicroModal.show('fail');
            });
        }
    });

        // Switchery
        if ($(".js-switch")[0]) {
            var elems = Array.prototype.slice.call(document.querySelectorAll('.js-switch'));
            elems.forEach(function (html) {
                if(!html.getAttribute('data-switchery')) {
                    var switchery = new Switchery(html, {
                        color: '#95b116'
                    });
                }
            });
        }

    document.querySelectorAll('.content-block-info').forEach((div) => {
        let isFlipped = false;

        div.addEventListener('mouseover', function() {
            if (!isFlipped) {
                this.style.transform = 'rotateY(360deg)';
                isFlipped = true;
            }
        });

        div.addEventListener('click', function() {
            if (isFlipped) {
                this.style.transform = 'rotateY(0deg)';
                isFlipped = false;
            } else {
                this.style.transform = 'rotateY(360deg)';
                isFlipped = true;
            }
        });
    });
},false);
