import FileSaver from 'file-saver'

export async function saveAsPNG(renderer, inputLayers, Sigma) {
    const { width, height } = renderer.getDimensions()

    // This pixel ratio is here to deal with retina displays.
    // Indeed, for dimensions W and H, on a retina display, the canvases
    // dimensions actually are 2 * W and 2 * H. Sigma properly deals with it, but
    // we need to readapt here:
    const pixelRatio = window.devicePixelRatio || 1

    const tmpRoot = document.createElement('div')
    tmpRoot.style.width = `${width}px`
    tmpRoot.style.height = `${height}px`
    tmpRoot.style.position = "absolute"
    tmpRoot.style.right = "101%"
    tmpRoot.style.bottom = "101%"
    document.body.appendChild(tmpRoot)

    // Instantiate sigma:
    const tmpRenderer = new Sigma(renderer.getGraph(), tmpRoot, renderer.getSettings())

    // Copy camera and force to render now, to avoid having to wait the schedule /
    // debounce frame:
    tmpRenderer.getCamera().setState(renderer.getCamera().getState())
    tmpRenderer.refresh()

    // Create a new canvas, on which the different layers will be drawn:
    const canvas = document.createElement('canvas')
    canvas.setAttribute('width', width * pixelRatio + '')
    canvas.setAttribute('height', height * pixelRatio + '')
    const ctx = canvas.getContext('2d')

    // For each layer, draw it on our canvas:
    const canvases = tmpRenderer.getCanvases()
    const layers = inputLayers ? inputLayers.filter((id) => !!canvases[id]) : Object.keys(canvases);
    layers.forEach((id) => {
        ctx.drawImage(
            canvases[id],
            0,
            0,
            width * pixelRatio,
            height * pixelRatio,
            0,
            0,
            width * pixelRatio,
            height * pixelRatio,
        )
    })

    // Save the canvas as a PNG image:
    canvas.toBlob((blob) => {
        if (blob) FileSaver.saveAs(blob, 'NetzderWoche.png')

        // Cleanup:
        tmpRenderer.kill()
        tmpRoot.remove()
    }, 'image/png')
}